import { PLATFORM } from "aurelia-pal";
import {
  Router,
  Redirect,
  RouterConfiguration,
  NavigationInstruction,
  Next,
} from "aurelia-router";
import { inject } from "aurelia-framework";
import { AuthService } from "auth-service";
import { AppConfig } from "app-config";

@inject(AuthService)
class AuthStep {
  constructor(private AuthService: AuthService) {}
  async run(navInstruction: NavigationInstruction, next: Next): Promise<any> {
    if (navInstruction.config.name == "authcallback") {
      await this.AuthService.HandleAuthQueryResponse();
      return next.cancel(new Redirect("welcome"));
    }
    if (navInstruction.config.name == "welcome") {
      if (this.AuthService.Authorized() == false) {
        this.AuthService.StartLogin();
      }
    }
    return next();
  }
}

@inject(AppConfig)
export class App {
  public router: Router;

  constructor(private AppConfig: AppConfig) {
    window.setTimeout(() => {
      //     window.location.reload();
    }, 20000);

    const params = new URLSearchParams(window.location.search);
    if (params.get("theme") == "light") {
      document.getElementsByTagName("html")[0].classList.add("light");
    }
  }

  public configureRouter(
    config: RouterConfiguration,
    router: Router
  ): Promise<void> | PromiseLike<void> | void {
    config.title = "Baseball Dashboard";

    const navStrat = (instruction: NavigationInstruction) => {
      debugger;
    };

    config.options.pushState = true;
    config.addAuthorizeStep(AuthStep);
    config.map([
      {
        route: "/auth",
        name: "authcallback",
        moduleId: PLATFORM.moduleName("./blank"),
        title: "Authenticating",
      },
      {
        route: ["", "welcome"],
        name: "welcome",
        moduleId: PLATFORM.moduleName("./root-dashboard"),
        nav: true,
        title: "Baseball dashboard",
      },
      {
        route: ["d"],
        name: "dashboard",
        moduleId: PLATFORM.moduleName("./dashboard"),
        nav: false,
        title: "Dasboard",
      },
      {
        route: "/c/:sessionname",
        name: "session",
        moduleId: PLATFORM.moduleName("./viewer"),
        title: "Dashboard viewer",
      },
    ]);

    this.router = router;
  }
}
