import { inject } from "aurelia-framework";
import { HttpClient } from "aurelia-http-client";

interface AppConfigStruct {
  api: string;
}

@inject(HttpClient)
export class AppConfig {
  config: AppConfigStruct = null;
  onChanges: Function = null;

  constructor(private HttpClient: HttpClient) {
    try {
      const openidconfig = window.localStorage.getItem("appconfig");
      if (openidconfig != null) {
        this.config = JSON.parse(openidconfig);
      }
    } catch (ex) {
      console.error("failed to recover openidconfig");
    }

    HttpClient.get("/app-config.json").then((resp) => {
      try {
        this.config = JSON.parse(resp.response);
        window.localStorage.setItem("appconfig", resp.response);

        if (this.onChanges != null) {
          this.onChanges();
        }
      } catch (ex) {
        console.error("failed to receive openidconfig");
      }
    });
  }

  BaseWs(): string {
    const baseHttp = this.BaseHttp();

    if (baseHttp.startsWith("http")) {
      return baseHttp.replace("http", "ws");
    }

    return baseHttp;
  }

  BaseHttp(): string {
    if (this.config == null || this.config.api == "") {
      return `${document.location.protocol}//${document.location.host}`;
    }

    return `${this.config.api}`;
  }
}
