import { DevMode } from "./dev-mode";
import { inject } from "aurelia-framework";

@inject(DevMode)
export class JsonInjector {
  private jsonPicker: HTMLInputElement;

  private on: boolean;

  constructor(DevMode: DevMode) {
    this.on = DevMode.IsDev();
  }

  onSelectJSON() {
    this.jsonPicker.click();
  }

  onJSONChanged(event: Event) {
    let picker = event.target as HTMLInputElement;
    if (picker.files.length > 0) {
      let f: File = picker.files[0];
      let reader = new FileReader();

      reader.onerror = () => {
        alert("unable to read file");
      };
      reader.onload = (_: Event) => {
        try {
          let jsonString = reader.result as string;

          JSON.parse(jsonString);
          localStorage.setItem("override-json", jsonString);
          window.location.reload();
        } catch (error) {
          alert("Failed to parse JSON");
        }
        debugger;
      };

      reader.readAsText(f);
    }
  }
}
