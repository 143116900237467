import { autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-http-client";
import { AuthService } from "./auth-service";

import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class ConfigsService {
  public static EventConfigReady = "EventConfigReady";

  private config: { [key: string]: any };

  private storage: Storage;

  private sessionStorage: Storage;

  constructor(
    private Http: HttpClient,
    private EventAggregator: EventAggregator,
    private AuthService: AuthService
  ) {
    // TODO: HttpClient should be authorized and have jwt within Authorization header
    this.storage = window.localStorage;

    this.sessionStorage = window.sessionStorage;

    let savedConfig = this.storage.getItem("config");
    if (savedConfig == null) {
      this.config = {};
    }

    this.fetchConfig();
  }

  async fetchTeamConfig(key: string): Promise<any> {
    let token = await this.AuthService.liveAccessToken();

    if (token == "") {
      return;
    }

    let resp = await this.Http.createRequest(`/apiconfigv1/defaults/${key}`)
      .asGet()
      .withHeader("Authorization", "Bearer " + token)
      .send();

    if (resp.statusCode != 200 || resp.mimeType != "application/json") {
      console.error("bar response from config service");
      return;
    }

    // todo: not sure why I'm saving it. I think I was going to use it for something
    this.sessionStorage.setItem(`team-config-${key}`, resp.response);

    return JSON.parse(resp.response);
  }

  async fetchConfig() {
    let token = await this.AuthService.liveAccessToken();

    if (token == "") {
      return;
    }

    let resp = await this.Http.createRequest("/apiconfigv1/")
      .asGet()
      .withHeader("Authorization", "Bearer " + token)
      .send();

    if (resp.statusCode !== 200) {
      console.error("Failed to collect config");
      return false;
    }

    if (resp.mimeType != "application/json") {
      console.error("bad content type for config");
      return false;
    }

    this.config = JSON.parse(resp.response);
    this.storage.setItem("config", resp.response);
    this.EventAggregator.publish(
      ConfigsService.EventConfigReady,
      Object.assign({}, this.config)
    );
  }

  getValue(key: string): string {
    return this.config[key] || null;
  }

  async update(key: string, value: string) {
    this.config ||= {};
    this.config[key] = value;

    let token = await this.AuthService.liveAccessToken();

    if (token == "") {
      return true;
    }

    let resp = await this.Http.createRequest("/apiconfigv1/")
      .asPost()
      .withContent(JSON.stringify(this.config))
      .withHeader("Authorization", "Bearer " + token)
      .send();

    if (resp.statusCode !== 200) {
      console.error("Unable to save config");
      return false;
    }

    this.storage.setItem("config", JSON.stringify(this.config));
  }
}
