import { autoinject, bindable, observable } from "aurelia-framework";
import { CustomiseViewService } from "./customize-view.service";
import { EventAggregator } from "aurelia-event-aggregator";

import { MetricValue, MetricIndicator } from "./metrics";
import { BaseballService, timestampConverter } from "./baseball.service";
import { SessionsService } from "./sessions.service";

import { Vector3 } from "./vector3";

//import { Sortable } from "@shopify/draggable";

import { GridStack } from "gridstack";
import { ConfigsService } from "./hke-configs.service";

import { AppConfig } from "app-config";
import { ResizedDetails } from "./resizeable";

import { MessagingAPI } from "./messaging.service";

interface FontMetrics {
  weight: string;
  size: number;
  family: string;
}

interface IncomingMetric {
  ValueOf: { StringValue: string; NumberValue: number };
}

type IncomingMetrics = { [key: string]: IncomingMetric };

interface DashboardItem {
  cell: MetricIndicator;
  val: MetricValue;
}

class LayoutGeom {
  x: number;
  y: number;
  w: number;

  constructor() {
    this.x = 1;
    this.y = 1;
    this.w = 3;
  }
}

type SavedLayout = { [key: string]: LayoutGeom };

enum ConnectionStatus {
  E_STATUS_UNKNOWN = 0,
  E_STATUS_CONNECTING = 1,
  E_STATUS_READING = 2,
  E_STATUS_CLOSED = 3,
}

@autoinject
export class Dashboard {
  private ws: WebSocket;
  metricValues: { [key: string]: MetricValue } = {};
  private sessionName: string;

  private updateGridToken: any = null;

  private availableMetrics: MetricIndicator[] = [];
  private availableMetricsMap: { [key: string]: MetricIndicator } = {};
  private selectedMetrics: { [key: string]: boolean } = {};
  private selectedMetricsArray: string[] = [];

  @bindable sourceMetrics: boolean;
  @bindable autosaveLayout: string = "true";

  private pitchZoneSpots: Vector3[];

  private MetricCursorIndex?: number = null;
  cellValues: DashboardItem[];

  private gridstackcontainer: Element;

  SelectedEventMetrics: IncomingMetrics;
  ReceivedMetrics: IncomingMetrics[] = [];

  Status: ConnectionStatus = ConnectionStatus.E_STATUS_UNKNOWN;

  timeTitle = "Pitch info";
  gridStackManager: GridStack = null;

  mobileview: string = null;

  private layoutDebounce: number;

  private getTextSizeCanvas: HTMLCanvasElement = null;

  //@observable
  //private pitches = 0;

  //pitchesChanged(newValue, oldValue) {
  //  console.log("Pitch ", newValue, " received");
  //  console.log(this.metricValues["time"]);
  //}

  constructor(
    private customiseViewService: CustomiseViewService,
    private ea: EventAggregator,
    private dataService: BaseballService,
    private sessionsService: SessionsService,
    private AppConfig: AppConfig,
    private ConfigsService: ConfigsService,
    private MessagingAPI: MessagingAPI
  ) {
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);

    this.windowResizeHandler = this.windowResizeHandler.bind(this);
    this.availableMetrics = this.dataService.availableMetrics;

    this.pitchZoneSpots = [];

    if (/Mobi/.test(navigator.userAgent)) {
      this.mobileview = "metrics";
    }

    this.availableMetrics.forEach((el) => {
      this.availableMetricsMap[el.key] = el;
    });

    // TODO: move to attached and make counterpart on detached to unsubscribe
    this.ea.subscribe(CustomiseViewService.EventCustomiseChanged, () => {
      this.restoreFilter();
    });

    this.ea.subscribe(SessionsService.EventSessionNameChanged, () => {
      this.sessionName = this.sessionsService.GetSessionName();
      this.updateSessionName();
    });
  }

  updateSessionName() {
    if (this.ws != null) {
      this.ws.close();
    }

    this.reloadSource();
  }

  getFontMetrics(element): FontMetrics {
    const fontWeight =
      window.getComputedStyle(element, null).getPropertyValue("font-weight") ||
      "normal";

    const fontSize =
      window.getComputedStyle(element, null).getPropertyValue("font-size") ||
      "16px";

    const fontFamily =
      window.getComputedStyle(element, null).getPropertyValue("font-family") ||
      "Roboto";

    return {
      weight: fontWeight,
      size: parseInt(fontSize.replace("px", "")),
      family: fontFamily,
    };
  }

  getCanvasFont(fontMetrics: FontMetrics): string {
    return `${fontMetrics.weight} ${fontMetrics.size}px ${fontMetrics.family}`;
  }

  readjustTextSize(element: HTMLElement, maxSize: number, targetWidth: number) {
    const canvas =
      this.getTextSizeCanvas ||
      (this.getTextSizeCanvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");

    var textWidth = 1e20;

    var fontMetrics = this.getFontMetrics(element);
    textWidth = context.measureText(element.innerText).width;
    while (textWidth > targetWidth || fontMetrics.size > maxSize) {
      fontMetrics.size = fontMetrics.size - 1;
      context.font = this.getCanvasFont(fontMetrics);
      textWidth = context.measureText(element.innerText).width;
    }

    while (textWidth < targetWidth && fontMetrics.size < maxSize) {
      fontMetrics.size = fontMetrics.size + 1;
      context.font = this.getCanvasFont(fontMetrics);
      textWidth = context.measureText(element.innerText).width;
    }

    element.style.fontSize = fontMetrics.size + "px";
  }

  onResizeTile(event: any, details: ResizedDetails) {
    const cell = event.target as HTMLElement;
    const title = cell.getElementsByClassName("--title")[0] as HTMLElement;
    const value = cell.getElementsByClassName("--value")[0] as HTMLElement;

    const cellContent = cell.getElementsByClassName(
      "--cell-content"
    )[0] as HTMLElement;
    const cellStyle = getComputedStyle(cellContent);

    const paddings =
      parseFloat(cellStyle.paddingTop) + parseFloat(cellStyle.paddingBottom);

    var maxFontSize = (cellContent.clientHeight - paddings) * 0.3;

    const internalPadding = 40;

    this.readjustTextSize(
      title,
      maxFontSize,
      details.width - title.offsetLeft - internalPadding
    );

    this.readjustTextSize(
      value,
      maxFontSize,
      details.width - value.offsetLeft - internalPadding
    );
  }

  onSaveLayout() {
    if (this.autosaveLayout == "false") return;

    let data = this.gridStackManager.save();
    let serializedData = JSON.stringify(data);

    this.customiseViewService.SaveLayout(serializedData);

    // todo: only if authorized and not mobile
    if (/Mobi/.test(navigator.userAgent)) {
    } else {
      this.ConfigsService.update("layout", serializedData);
    }
  }

  onRestoreLayout() {
    // todo: only if authorized
    if (this.autosaveLayout == "true") {
      try {
        let layout = this.ConfigsService.getValue("layout");
        if (layout != null) {
          this.customiseViewService.SaveLayout(layout);
        }
      } catch (ex) {
        console.error("Failed to load layout");
      }
    }

    const savedLayoutStr = this.customiseViewService.GetLayout();
    if (savedLayoutStr !== null) {
      const savedLayout = JSON.parse(savedLayoutStr);
      this.gridStackManager.load(savedLayout, false);
    }
  }

  onCellInfo(key: string) {
    let data = this.gridStackManager.save();
    let serializedData = JSON.stringify(data);

    window.localStorage.setItem("layout", serializedData);
  }

  // reconnects to the server via websocket
  async reloadSource() {
    this.reset();

    this.Status = ConnectionStatus.E_STATUS_CONNECTING;

    let initConnectionResponse = await this.MessagingAPI.InitConnect(
      this.sessionName
    );

    if (initConnectionResponse == null) {
      return (this.sessionName = "");
    }

    let ws = new WebSocket(
      `${this.AppConfig.BaseWs()}/api/messaging/connect?u=${
        this.sessionName
      }&allRecords=t`
    );

    this.ws = ws;

    ws.onopen = (_) => {
      this.ReceivedMetrics = [];
      this.Status = ConnectionStatus.E_STATUS_READING;
    };
    ws.onclose = (event) => {
      console.log("WS closed");
      console.log(event);

      if (this.ws == ws) {
        this.ws = null;
      }

      ws = null;

      this.Status = ConnectionStatus.E_STATUS_CLOSED;

      if (event.type !== "close") {
        this.reloadSource();
      }
    };
    ws.onerror = (event) => {
      console.log("WS error");
      console.log(event);
    };
    ws.onmessage = (event) => {
      console.log(".");
      let values: IncomingMetrics;

      const parsed = JSON.parse(event.data);

      values = parsed.Values;

      /*
      console.log(
        "[",
        parsed.ts_hub - parsed.ts_database,
        ",",
        parsed.ts_sent - parsed.ts_hub,
        ",",
        new Date().getTime() - parsed.ts_sent,
        "]"
      );
*/
      let matches = this.ReceivedMetrics.filter(
        (metric: IncomingMetrics): boolean => {
          return (
            metric.time.ValueOf.NumberValue == values.time.ValueOf.NumberValue
          );
        }
      );

      if (matches.length == 0) {
        this.ReceivedMetrics.push(values);
      } else {
        matches.forEach((el: IncomingMetrics) => {
          Object.assign(el, values);
        });
      }

      if (this.MetricCursorIndex == null) {
        this.MetricCursorIndex = this.ReceivedMetrics.length - 1;
      } else {
        if (this.ReceivedMetrics.length - 2 == this.MetricCursorIndex) {
          this.MetricCursorIndex = this.ReceivedMetrics.length - 1;
        }
      }

      this.SelectedEventMetrics = values;

      this.ReloadSelectedMetricValues();
    };
  }

  reset() {
    this.metricValues = {};
    this.cellValues = [];
  }

  // Regenerated cells
  ReloadSelectedMetricValues() {
    if (this.ReceivedMetrics == null || this.ReceivedMetrics.length == 0) {
      this.reset();
      //this.pitches = 0;

      return;
    }

    if (this.MetricCursorIndex >= this.ReceivedMetrics.length) {
      this.MetricCursorIndex = this.ReceivedMetrics.length - 1;
    }
    let values = this.ReceivedMetrics[this.MetricCursorIndex];

    // if selected last metrics - show all pitchZoneSpots
    //  if (this.MetricCursorIndex == this.ReceivedMetrics.length - 1) {
    let els = this.ReceivedMetrics.map((el) => {
      if ("strike_zone_pos_x" in el) {
        if ("ValueOf" in el.strike_zone_pos_x) {
          return Vector3.New(
            el.strike_zone_pos_x.ValueOf.NumberValue,
            el.strike_zone_pos_z.ValueOf.NumberValue,
            el.strike_zone_pos_y.ValueOf.NumberValue
          );
        }
      }
      return null;
    });

    this.pitchZoneSpots = els.filter((el) => el != null);
    /* } else {
      var el = values;
      if ("ValueOf" in el.strike_zone_pos_x) {
        this.pitchZoneSpots = [
          Vector3.New(
            el.strike_zone_pos_x.ValueOf.NumberValue,
            el.strike_zone_pos_z.ValueOf.NumberValue,
            el.strike_zone_pos_y.ValueOf.NumberValue
          ),
        ];
      }
    }
*/
    //   console.log(values);
    this.metricValues = {};

    if (this.sourceMetrics) {
      this.sourceMetrics = false;
      this.customiseViewService.SetSessionSelectedMetrics(Object.keys(values));
    }

    this.cellValues = [];
    let missingDefs = [];

    // Iterate over received metrics
    for (let k of Object.keys(values)) {
      try {
        let valueOf: any;
        valueOf = values[k].ValueOf;

        if (k in this.availableMetricsMap == false) {
          if (k == "time") {
            this.metricValues[k] = {
              name: k,
              value: valueOf.NumberValue,
              state: "",
            };

            this.metricValues[k].displayValue = timestampConverter(
              this.metricValues[k]
            );
          }

          missingDefs.push(k);
          //         console.warn(`Missing definition for parameter ${k}`);
          continue;
        }

        let metricIndicator = this.availableMetricsMap[k];

        if (typeof valueOf.NumberValue === "number") {
          this.metricValues[k] = {
            name: k,
            value: valueOf.NumberValue,
            state: metricIndicator.stateForValue(valueOf.NumberValue),
          };
        } else if (typeof valueOf.StringValue === "string") {
          this.metricValues[k] = {
            name: k,
            value: valueOf.StringValue,
            state: MetricIndicator.DefaultState,
          };
        }

        if (typeof metricIndicator.valueConvertorFn == "function") {
          // TODO: make a copy of metric value to prevent value convertor function to modify original value
          this.metricValues[k].displayValue = metricIndicator.valueConvertorFn(
            this.metricValues[k]
          );
        } else {
          this.metricValues[k].displayValue = this.metricValues[k].value;
        }

        // Time should be filtered out and managed separately
        if (k == "time") {
          continue;
        }

        // Play index filtered out and replaced with currentPitch/total pitches
        if (k == "play_index") {
          continue;
        }

        if (this.selectedMetrics[k]) {
          this.cellValues.push({
            cell: this.availableMetricsMap[k],
            val: this.metricValues[k],
          });

          //             if (!this.layout[k]) this.layout[k] = new LayoutGeom();
        }
      } catch (ex) {
        console.error("Failed to process metric");
        console.error(ex);
      }
    }

    if (missingDefs.length > 0) {
      //      console.warn("Missing defs for params:", missingDefs.join(","));
    }
    /*
    let PitchIndexMetricValue = new MetricValue();
    PitchIndexMetricValue.value = `${this.MetricCursorIndex + 1}/${
      this.ReceivedMetrics.length
    }`;

    PitchIndexMetricValue.displayValue = PitchIndexMetricValue.value;
    PitchIndexMetricValue.state = "def";
    PitchIndexMetricValue.name = "play_index";

    this.cellValues.unshift({
      cell: this.availableMetricsMap["play_index"],
      val: PitchIndexMetricValue,
    });
*/
    // Draggable for tiles
    //
    if (this.updateGridToken != null) {
      window.clearTimeout(this.updateGridToken);
      this.updateGridToken = null;
    }

    this.updateGridToken = window.setTimeout(() => {
      this.updateGridToken = null;

      if (this.gridStackManager != null) {
        this.gridStackManager.destroy(false);
        this.gridStackManager = null;
      }

      this.gridStackManager = GridStack.init(
        {
          oneColumnSize: 400,
          itemClass: "cell",
          handle: this.mobileview == null ? ".--cell-content" : null,
          margin: "16px",
          float: this.mobileview == null,
          cellHeight: "110px",
        },
        ".dashboard-cells"
      );

      this.gridstackcontainer.addEventListener("change", () => {
        //debugger;
      });

      this.gridStackManager.engine.nodes.forEach((node) => {
        node.id = node.el["metricid"];
      });

      let onChangeOrig = this.gridStackManager.engine["onChange"];

      this.gridStackManager.engine["onChange"] = (nodes: any[]) => {
        onChangeOrig.apply(this.gridStackManager.engine, [nodes]);

        // Prevent bombarding ConfigsService on moving tiles
        if (this.layoutDebounce) {
          window.clearInterval(this.layoutDebounce);
          this.layoutDebounce = 0;
        }

        this.layoutDebounce = window.setTimeout(() => {
          this.onSaveLayout();
          this.layoutDebounce = 0;
        }, 1000);
      };

      this.onRestoreLayout();
    }, 100);

    ///    console.log(this.metricValues["time"].displayValue);
  }

  // Change cursor on list of metrics and reloads the data
  OnAdvanceMetrics(step: number) {
    if (this.MetricCursorIndex == null) {
      return;
    }

    let index = this.MetricCursorIndex + step;
    if (index < 0) {
      index = 0;
    }

    if (index > this.ReceivedMetrics.length - 1) {
      index = this.ReceivedMetrics.length - 1;
    }

    if (index != this.MetricCursorIndex) {
      this.MetricCursorIndex = index;

      console.log("Advance", step);
      this.ReloadSelectedMetricValues();
    }
  }

  restoreFilter() {
    this.selectedMetrics = this.customiseViewService.GetSelectedMetrics();

    console.log("restore filter");
    this.ReloadSelectedMetricValues();
  }

  windowResizeHandler(event: Event) {
    if (window.innerWidth > 768) {
      this.mobileview = "";
    } else {
      if (this.mobileview != null) {
        this.mobileview = "metrics";
      }
    }
  }

  handleVisibilityChange() {
    console.debug("visibilitychange", document.visibilityState, this.Status);
    if (document.visibilityState == "visible") {
      // for iOS.
      // if user switches the app, iOS disconnects websocket connections.
      //
      // this block handles switch browser back and tryes to connect back. in 1 second after switth
      //  and runs loop to be sure it is connected
      //
      window.setTimeout(() => {
        if (this.Status == ConnectionStatus.E_STATUS_CLOSED) {
          this.reloadSource();
        }
      }, 1000);

      window.setInterval((_: void) => {
        const hasSession =
          typeof this.sessionName == "string" && this.sessionName != "";
        if (this.Status == ConnectionStatus.E_STATUS_CLOSED && hasSession) {
          this.reloadSource();
        }
      }, 5000);
    }
  }

  keyListener: any;

  activate() {
    console.log("activate");
  }

  deactivate() {
    console.log("deactivate");
  }

  bind(bindingContext: Object, overrideContext: Object) {
    console.log("bind");
  }

  unbind() {
    console.log("unbind");
  }

  attached() {
    console.debug("attached");
    document.addEventListener("visibilitychange", this.handleVisibilityChange);

    this.restoreFilter();
    this.restoreSession();

    this.keyListener = (event: KeyboardEvent) => {
      this.OnKeyNext(event);
    };

    window.addEventListener("keydown", this.keyListener);
    window.addEventListener("resize", this.windowResizeHandler);
  }

  detached() {
    window.removeEventListener("keydown", this.keyListener);
    window.removeEventListener("resize", this.windowResizeHandler);
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }

  restoreSession() {
    this.sessionName = this.sessionsService.GetSessionName();

    if (this.sessionName != null) {
      this.reloadSource();
    }
  }

  onTileChanged() {
    console.log("onTimeChanged", arguments);
    debugger;
  }

  onGridChanged() {
    debugger;
    console.log("onGridChanged", arguments);
  }

  OnKeyNext(event: KeyboardEvent) {
    if (event.which == 39) {
      this.OnAdvanceMetrics(1);
    }
    if (event.which == 37) {
      this.OnAdvanceMetrics(-1);
    }
  }

  stressTest() {
    return;
    new Promise((resolve, reject) => {
      // Set up an array to store the WebSocket connections
      const sockets = [];

      // Set up a function to create a new WebSocket connection
      const createSocket = () => {
        const socket = new WebSocket(
          `${this.AppConfig.BaseWs()}/api/messaging/connect?u=${
            this.sessionName
          }&allRecords=t`
        );
        sockets.push(socket);

        // Set up a handler to remove the WebSocket connection from the array when it's closed
        socket.addEventListener("close", () => {
          const index = sockets.indexOf(socket);
          if (index > -1) {
            sockets.splice(index, 1);
          }
        });
      };

      // Set up a function to randomly create or close WebSocket connections
      function toggleConnections() {
        const randomNumber = Math.floor(Math.random() * 2); // 0 or 1
        if (randomNumber === 0 && sockets.length < 3) {
          createSocket();
        } else if (sockets.length > 0) {
          const index = Math.floor(Math.random() * sockets.length);
          const socket = sockets[index];
          socket.close();
        }

        scheduleTask();
      }

      function scheduleTask() {
        const randomWaitTime = Math.floor(Math.random() * 1000); // Wait up to 1 second between actions
        setTimeout(toggleConnections, randomWaitTime);
      }

      scheduleTask();
    });
  }
}
