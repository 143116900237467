import { autoinject } from "aurelia-framework";
import { CustomiseViewService, SelectedMetric } from "./customize-view.service";
import { BaseballService } from "./baseball.service";
import { MetricIndicator } from "./metrics";
import { SessionsService } from "./sessions.service";
import { Sortable } from "@shopify/draggable";
import { EventAggregator } from "aurelia-event-aggregator";

interface MetricsGroup {
  group: string;
  key: string;
  items: MetricIndicator[];
  checked: boolean;
}

@autoinject
export class CustomizeView {
  private availableMetrics: MetricIndicator[] = [];
  private readonlylink: string = "";
  private sortable: Sortable = null;
  private groups: MetricsGroup[];

  constructor(
    private customiseViewService: CustomiseViewService,
    private dataService: BaseballService,
    private sessions: SessionsService,
    private ea: EventAggregator
  ) {}

  toggleGroup(group: MetricsGroup) {
    group.checked = group.checked != true;
    group.items.forEach((el) => {
      this.customiseViewService.selectedMetrics[el.key] = group.checked;
    });

    console.log("selected metrics", this.customiseViewService.selectedMetrics);

    console.log("group items:", group.items);
  }

  toggleMetric(metric: MetricIndicator) {
    this.customiseViewService.selectedMetrics[metric.key] =
      this.customiseViewService.selectedMetrics[metric.key] != true;
  }

  buildGroups() {
    console.log("build groups");
    let groupsMap = this.availableMetrics.reduce((prev, item) => {
      prev[item.group] ??= [];
      prev[item.group].push(item);
      return prev;
    }, {});

    // string->MetricIndicator
    let groupKeyFn = (str: string): number => {
      let hash = 0;
      for (let i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    };

    this.groups = Object.keys(groupsMap).map((group) => {
      let key = groupKeyFn(group) + "";
      return { group, key, checked: true, items: groupsMap[group] };
    });
  }

  attached() {
    this.availableMetrics = this.dataService.availableMetrics;

    this.buildGroups();

    this.customiseViewService.GetSelectedMetrics();

    this.ea.subscribe(CustomiseViewService.EventCustomiseVisibility, () => {
      if (this.customiseViewService.IsVisible() && this.sortable == null) {
        window.setTimeout(() => {
          /*
          this.sortable = new Sortable(document.querySelectorAll(".list"), {
            draggable: ".listitem",
          });*/
        }, 100);
      }
    });
  }

  onGenerateReadonlyLink() {
    // TODO: Add automatic copy link to the clipboard and notification
    this.customiseViewService.SaveCustomise(false);
    this.customiseViewService
      .GenerateReadonlyLink(this.sessions.GetSessionName())
      .then((link: string) => (this.readonlylink = link));
  }

  detached() {}

  onCancelCustomise() {
    this.customiseViewService.HideCustomize();
  }

  onSaveCustomise() {
    this.customiseViewService.SaveCustomise(false);
    this.customiseViewService.HideCustomize();
  }
}
