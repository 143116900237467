import { DevMode } from "./dev-mode";
import { inject } from "aurelia-framework";
import { HttpClient, RequestBuilder } from "aurelia-http-client";

@inject(DevMode, HttpClient)
export class AuthService {
  openidconfig: any = null;
  onOpenIdReceived: Function = null;

  homevenues: string[] = [];
  constructor(private DevMode: DevMode, private HttpClient: HttpClient) {
    try {
      const openidconfig = window.localStorage.getItem("openidcfg");
      if (openidconfig != null) {
        this.openidconfig = JSON.parse(openidconfig);
      }
    } catch (ex) {
      console.error("failed to recover openidconfig");
    }

    HttpClient.get("/openid-configuration.json").then((resp) => {
      try {
        this.openidconfig = JSON.parse(resp.response);
        window.localStorage.setItem("openidcfg", resp.response);

        if (this.onOpenIdReceived != null) {
          this.onOpenIdReceived();
        }
      } catch (ex) {
        console.error("failed to receive openidconfig");
      }
    });
  }

  private ClientId = "baseball-stats-dashboard";

  authItem = "auth";

  Authorized(): boolean {
    if (
      this.DevMode.IsDev() ||
      window.location.hostname.startsWith("192.168.") ||
      window.location.hostname.startsWith("172.16.") ||
      window.location.hostname.startsWith("127.") ||
      window.location.hostname.startsWith("10.")
    ) {
      return true;
    }

    return window.localStorage.getItem(this.authItem) != null;
  }

  cleanup() {
    window.localStorage.removeItem(this.authItem);
    window.localStorage.removeItem("authObj");
    window.localStorage.removeItem("idtoken");
    window.localStorage.removeItem("refresh");

    this.HttpClient.configure((builder: RequestBuilder) => {
      builder.withHeader("Authorization", "");
    });
  }

  Logout() {
    const idtoken = window.localStorage.getItem("idtoken");

    this.cleanup();

    const dest =
      this.openidconfig.end_session_endpoint +
      (idtoken == null
        ? ``
        : `?id_token_hint=${idtoken}&post_logout_redirect_uri=` +
          encodeURIComponent(window.location.origin));
    window.location.href = dest;
  }

  StartLogin() {
    if (this.openidconfig == null) {
      this.onOpenIdReceived = () => {
        this.StartLogin();
      };
      return;
    }

    window.location.href =
      this.openidconfig.authorization_endpoint +
      `?client_id=${this.ClientId}&redirect_uri=` +
      encodeURIComponent(window.location.origin + "/auth") +
      "&scope=offline_access openid" +
      "&response_type=code";
  }

  // code -> access_token+refresh_token
  async exchangeCode(code: string) {
    const data = new URLSearchParams();
    data.append("grant_type", "authorization_code");
    data.append("client_id", this.ClientId);
    data.append("redirect_uri", window.location.origin + "/auth");
    data.append("code", code);

    try {
      const response = await this.HttpClient.createRequest(
        this.openidconfig.token_endpoint
      )
        .withContent(data.toString())
        .withHeader("Content-Type", "application/x-www-form-urlencoded")
        .asPost()
        .send();

      if (response.statusCode != 200) {
        this.cleanup();

        window.location.reload();
        return;
      }

      const authpayload = JSON.parse(response.response);

      window.localStorage.setItem("tkn", response.response);
      window.localStorage.setItem("idtoken", authpayload.id_token);

      this.saveRefreshToken(authpayload.refresh_token);
      this.saveAccessToken(authpayload.access_token);
    } catch (Ex) {
      console.error("failed to exchange code to token");

      this.cleanup();

      window.location.href = window.location.origin;
    }
  }

  saveRefreshToken(token: string) {
    window.localStorage.setItem("refresh", token);
  }

  saveAccessToken(token: string) {
    window.localStorage.setItem("auth", token);
    const payload = atob(token.split(".")[1]);
    window.localStorage.setItem("authObj", payload);

    this.homevenues = this._extractVenues(payload);

    this.HttpClient.configure((builder: RequestBuilder) => {
      builder.withHeader("Authorization", "Bearer " + token);
    });
  }

  async HandleAuthQueryResponse() {
    const accessTokens = window.location.hash
      .split("&")
      .map((el) => el.split("="))
      .filter((el) => el[0].endsWith("access_token"));

    const codes = window.location.search
      .split("&")
      .map((el) => el.split("="))
      .filter((el) => el[0].endsWith("code"));

    if (codes.length > 0) {
      // Exchange code with token endpoint to get refresh token
      await this.exchangeCode(codes[0][1]);
    }

    accessTokens.forEach((el) => {
      this.saveAccessToken(el[1]);
    });
  }

  _extractVenues(payload: any): string[] {
    if (payload == null) return [];
    try {
      return payload["realm_access"]["roles"]
        .filter((el: string) => el.startsWith("V_"))
        .map((el: string) => {
          return el.substring(el.lastIndexOf("_") + 1, el.length);
        });
    } catch (ex) {
      return [];
    }
  }

  HomeVenues() {
    const payload = JSON.parse(this.localStorage().getItem("authObj"));

    this.homevenues = this._extractVenues(payload);

    return this.homevenues;
  }

  UserMail() {
    let authObj = JSON.parse(this.localStorage().getItem("authObj"));

    return authObj ? authObj["email"] : "no-email";
  }

  Username() {
    let authObj = JSON.parse(this.localStorage().getItem("authObj"));

    return authObj
      ? [authObj["given_name"], authObj["family_name"]].join(" ")
      : "not authenticated";
  }

  localStorage() {
    return window.localStorage;
  }

  // retrieve locally stored access_token, decodes it,
  // verifies it has valid exp. if not, then exchange refresh_token to get a new access_token
  // TODO: new access_token not received, revoked, etc... such situation is not handeled here
  async liveAccessToken(): Promise<string> {
    const auth = window.localStorage.getItem("auth");

    if (auth == null) {
      return "";
    }

    const authObject = JSON.parse(atob(auth.split(".")[1]));

    const left = authObject["exp"] * 1000 - Date.now();
    if (left <= 60000) {
      // Refresh with refresh_token

      const refreshtoken = window.localStorage.getItem("refresh");
      if (refreshtoken == null) return "";

      const data = new URLSearchParams();
      data.append("grant_type", "refresh_token");
      data.append("client_id", this.ClientId);
      data.append("redirect_uri", window.location.origin + "/auth");
      data.append("refresh_token", refreshtoken);

      try {
        const response = await this.HttpClient.createRequest(
          this.openidconfig.token_endpoint
        )
          .withContent(data.toString())
          .withHeader("Content-Type", "application/x-www-form-urlencoded")
          .asPost()
          .send();

        if (response.statusCode != 200) {
          console.log("failed to exchange access token with refresh token");
          return "";
        }

        if (response.responseType != "json") {
          console.log("wrong response type");
          return "";
        }

        console.log(response.content);

        const respPayload = JSON.parse(response.content);
        this.saveRefreshToken(respPayload.refresh_token);
        this.saveAccessToken(respPayload.access_token);

        return respPayload.access_token;
      } catch (err) {
        console.error(err);

        this.cleanup();
        return "";
      }
    }

    return auth;
  }

  async AuthHeader(): Promise<string> {
    const auth = await this.liveAccessToken();
    if (auth == null) {
      return "";
    }

    // verify expiration
    // if expired, refresh to a new one with refresh_token

    return `Bearer ${auth}`;
  }
}
