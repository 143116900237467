import {
  MetricValue,
  MetricUnitDef,
  NewMetric,
  MetricIndicator,
  ValueConvertorFunction,
  IndicatorStatus,
} from "./metrics";
let metricsDef = require("../static/fields.json");
import { ConfigsService } from "./hke-configs.service";

import { HttpClient } from "aurelia-http-client";

function numberSuffix(
  value: number | string,
  decSize: number,
  suffix: string
): string {
  return numberSuffixPrefix(value, decSize, suffix, "");
}

function numberSuffixPrefix(
  value: number | string,
  decSize: number,
  suffix: string,
  prefix: string
): string {
  if (typeof value === "number") {
    if (typeof suffix !== "string") {
      return value.toString();
    }

    return prefix + value.toFixed(decSize) + suffix;
  }

  if (typeof value === "string") {
    if (value === "") {
      return "";
    }

    return numberSuffixPrefix(parseFloat(value), decSize, suffix, prefix);
  }
}

function feetInches(value: number): string {
  return (
    Math.floor(value) +
    "'" +
    (12 * (value - Math.round(value))).toFixed(0) +
    '"'
  );
}

export function timestampConverter(value: MetricValue): string {
  return new Date(
    value.value / 1e3 + 946684800000 /*seconds since 1970 to 2000*/
  ).toLocaleTimeString();
}

function rpmConvertor(value: MetricValue): string {
  return numberSuffix(value.value, 0, "rpm");
}

function movementConvertor(value: MetricValue): string {
  return numberSuffix(value.value, 2, "″");
}

function degConvertor(value: MetricValue): string {
  return numberSuffix(value.value, 1, "deg");
}

function speedConvertor(value: MetricValue): string {
  if (window.localStorage.getItem("unitsystem") == "metric") {
    return kmhConvertor(value);
  }

  return mphConvertor(value);
}

function kmhConvertor(value: MetricValue): string {
  return numberSuffix(value.value, 2, "kph");
}

function mphConvertor(value: MetricValue): string {
  return numberSuffix(value.value, 2, "mph");
}

function makeMultiplierDecimalsConverter(
  multIn: number,
  dec: number,
  units: MetricUnitDef[]
): ValueConvertorFunction {
  let suffix = "";
  let prefix = "";

  let mult = typeof multIn === "number" ? multIn : 1;

  let placeholder = "-";

  if (units.length > 0) {
    let unitDef = units[0];

    if (typeof unitDef !== "undefined") {
      suffix = unitDef.suffix || "";
      prefix = unitDef.prefix || "";

      mult = mult * (typeof unitDef.mult === "number" ? unitDef.mult : 1);

      placeholder = unitDef.placeholder || "-";
    }
  }

  return function (value: MetricValue): string {
    let v = value.value;

    if (typeof v === "string") {
      let parsed = parseFloat(v);

      if (isNaN(parsed)) {
        if (v == "") {
          return placeholder;
        }
        return v;
      }

      v = parsed;
    } else if (typeof v !== "number") {
      return "-";
    }

    if (typeof mult === "number") {
      v = v * mult;
    }

    if (suffix == "feet/inches") {
      return feetInches(v);
    }

    if (typeof dec === "number") {
      return numberSuffixPrefix(v, dec, suffix, prefix);
    }

    return numberSuffixPrefix(v, 0, suffix, prefix);
  };
}

interface MetricDefAppearance {
  color: string;
  icon: string;
}

export interface MetricDef {
  column_name: string;
  plain: boolean;
  version: string; // x.y.z
  display_name: string;
  group: string;
  appearance: MetricDefAppearance;
  multiplier: number;
  decimal_places: number;
  states: IndicatorStatus[];
  units: MetricUnitDef[];

  widget_params: any;
  value_type: string;
  widget: string;
}

// is ver1(x.y.z) > ver2(x.y.z)
function versionMore(ver1: string, ver2: string): boolean {
  ver1 ||= "0.0.0";
  ver2 ||= "0.0.0";
  let v1 = ver1.split(".");
  let v2 = ver2.split(".");
  for (let i = 0; i < 3; i++) {
    if (v1[i] > v2[i]) {
      return true;
    }
  }
  return false;
}

function mergeFields(
  metricsA: MetricDef[],
  metricsB: MetricDef[]
): MetricDef[] {
  const result: MetricDef[] = [];

  metricsA.forEach((el: MetricDef) => {
    const match = metricsB.filter(
      (el2: MetricDef) => el2.column_name == el.column_name
    );

    //match is metricsB tries to override metricsA

    if (match.length == 0 || versionMore(el.version, match[0].version)) {
      result.push(el);
    } else {
      result.push(match[0]);
    }
  });

  return result;
}

export class BaseballService {
  public availableMetrics: MetricIndicator[] = [];

  public strikeZone: MetricDef = null;

  static inject = [HttpClient, ConfigsService];

  constructor(
    private HttpClient: HttpClient,
    private ConfigsService: ConfigsService
  ) {
    let targetMetrics = metricsDef;

    let cachedMetrics = this.loadCachedConfig();
    if (cachedMetrics != null) {
      this.updateAvailableMetrics(cachedMetrics);
    }

    HttpClient.get("/fields.json").then((resp) => {
      if (resp.mimeType != "application/json" || resp.statusCode != 200) {
        return;
      }

      targetMetrics = JSON.parse(resp.response) as MetricDef[];

      // Inject team specific metrics
      this.ConfigsService.fetchTeamConfig(`fields`).then((resp) => {
        if (resp != null) {
          const fields = resp["fields"] as MetricDef[];
          targetMetrics = mergeFields(targetMetrics, fields);
          localStorage.setItem("override-json", JSON.stringify(targetMetrics));
          this.updateAvailableMetrics(targetMetrics);
        }
      });

      let cachedMetrics = this.loadCachedConfig();
      targetMetrics = mergeFields(targetMetrics, cachedMetrics);
      localStorage.setItem("override-json", JSON.stringify(targetMetrics));

      this.updateAvailableMetrics(targetMetrics);
    });
  }

  loadCachedConfig(): MetricDef[] {
    let overrideJson = localStorage.getItem("override-json");
    if (overrideJson != null) {
      try {
        let metrics = JSON.parse(overrideJson);

        let valid = false;

        if (Array.isArray(metrics)) {
          valid = true;
          metrics.forEach((el: any) => {
            if ("column_name" in el == false) {
              valid = false;
            }
          });
        }

        if (!valid) {
          localStorage.removeItem("override-json");
        } else {
          return metrics;
        }
      } catch (error) {
        console.error("unable to load cached metrics");
      }
    }
    return [];
  }

  updateAvailableMetrics(targetMetrics: any[]) {
    this.availableMetrics = targetMetrics
      .map((el: MetricDef) => {
        let converter: ValueConvertorFunction;

        if (el.widget == "pitchzone") {
          this.strikeZone = el;
          return null;
        }

        if (el.column_name == "time") {
          converter = timestampConverter;
        } else {
          if (el.plain) {
            converter = null;
          } else {
            converter = makeMultiplierDecimalsConverter(
              el.multiplier,
              el.decimal_places,
              el.units || []
            );
          }
        }

        let color = "green";
        let icon = "generic-metric.svg";

        if (typeof el.appearance !== "undefined" && el.appearance !== null) {
          color = el.appearance.color || color;
          icon = el.appearance.icon || icon;

          if (icon.indexOf(".") == -1) {
            icon = icon + ".svg";
          }

          if (color.charAt(0) == "#") {
            color = color.slice(1, color.length);
          }
        }

        return NewMetric(
          el.column_name,
          el.display_name,
          color,
          el.group || "no-group",
          converter,
          icon,
          el.units,
          el.states,
          el.multiplier
        );
      })
      .filter((el) => el != null);
  }

  GetPitchZoneDef(): MetricDef {
    return this.strikeZone;
  }

  GetAvailableMetrics(): MetricIndicator[] {
    return this.availableMetrics;
  }
}
