import { autoinject } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";

import { MetricIndicator } from "./metrics";
import { BaseballService } from "./baseball.service";

import { HttpClient } from "aurelia-http-client";
import { ConfigsService } from "./hke-configs.service";

export type SelectedMetric = { [key: string]: boolean };

@autoinject
export class CustomiseViewService {
  static EventCustomiseChanged = "EventCustomiseChanged";
  static EventCustomiseVisibility = "EventCustomiseVisibility";

  selectedMetrics: SelectedMetric = {};

  private localStorage: Storage;
  private sessionStorage: Storage;

  private showCustomise: boolean = false;

  private availableMetrics: MetricIndicator[] = [];

  constructor(
    private ea: EventAggregator,
    private dataService: BaseballService,
    private HttpClient: HttpClient,
    private ConfigsService: ConfigsService
  ) {
    this.availableMetrics = this.dataService.availableMetrics;
    this.localStorage = window.localStorage;
    this.sessionStorage = window.sessionStorage;
  }

  IsVisible(): boolean {
    return this.showCustomise;
  }
  ShowCustomize() {
    this.showCustomise = true;

    this.ea.publish(CustomiseViewService.EventCustomiseVisibility, true);
  }

  HideCustomize() {
    this.showCustomise = false;

    this.ea.publish(CustomiseViewService.EventCustomiseVisibility, false);
  }

  // Tiles layout for dashboard
  SaveLayout(layoutObj: any) {
    if (typeof layoutObj == "object") {
      layoutObj = JSON.stringify(layoutObj);
    }
    window.localStorage.setItem("layout", layoutObj);
  }

  GetLayout(): any {
    return window.localStorage.getItem("layout");
  }

  SetSessionSelectedMetrics(metricsKeys) {
    this.selectedMetrics = {};

    metricsKeys.forEach((el) => {
      this.selectedMetrics[el] = true;
    });

    this.SaveCustomise(true);
  }

  GetSelectedMetrics() {
    let savedFilter = this.sessionStorage.getItem("filter");
    if (savedFilter == null) {
      savedFilter = this.localStorage.getItem("filter");
    }

    if (savedFilter != null) {
      try {
        this.selectedMetrics = JSON.parse(savedFilter);
      } catch (ex) {
        console.error(ex);
        this.selectedMetrics = {};
        this.availableMetrics.forEach((el) => {
          this.selectedMetrics[el.key] = true;
        });
      }
    } else {
      this.selectedMetrics = {};
      this.availableMetrics.forEach((el) => {
        this.selectedMetrics[el.key] = true;
      });
    }

    return Object.assign({}, this.selectedMetrics);
  }

  // todo: add Authorization: Bearer token
  async GenerateReadonlyLink(sessionName: string): Promise<string> {
    const resp = await this.HttpClient.post("/api/messaging/l", {
      session: sessionName,
      meta: { layout: this.ConfigsService.getValue("layout") },
      metrics: Object.keys(this.selectedMetrics)
        .filter((el) => this.selectedMetrics[el])
        .concat(
          "time",
          "strike_zone_pos_x",
          "strike_zone_pos_y",
          "strike_zone_pos_z"
        ),
    });

    let data = JSON.parse(resp.response);
    return data["link"];
  }

  SaveCustomise(sessionStorage: boolean) {
    if (typeof sessionStorage === "boolean" && sessionStorage === true) {
      this.sessionStorage.setItem(
        "filter",
        JSON.stringify(this.selectedMetrics)
      );
    } else {
      this.localStorage.setItem("filter", JSON.stringify(this.selectedMetrics));
    }

    debugger;
    this.ea.publish(CustomiseViewService.EventCustomiseChanged);
  }
}
